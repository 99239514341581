import React from 'react';
// import PhoneIcon from '@material-ui/icons/Phone';
// import LocationOnIcon from '@material-ui/icons/LocationOn';
// import EmailIcon from '@material-ui/icons/Email';

function Top() {

    return (
        <div>
            {/* <PhoneIcon /><span style={{ color: "black", marginRight: "10px" }}>Tel: +85258031778</span>
            <LocationOnIcon /><span style={{ color: "black", marginRight: "10px" }}>Oever 53162 GR  Rhoon, Netherland</span>
            <EmailIcon /><span style={{ color: "black", marginRight: "10px" }}>info@oceanqueensmarine.com</span> */}
        </div>
    );
}

export default Top;